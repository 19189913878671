import * as React from "react"

const StatsSRTP = () => (
  <div className="row inline-stats">
    <div className="col-12 col-xl-8 body-stats corners">
      <h4 className="grey-text">TOUCHING HEARTS AND MINDS</h4>
      <hr />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-01.svg"
          />
          <h1>12</h1>
          <p>Translations Project translators</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-16.svg"
          />
          <h1>18</h1>
          <p>Books Translated</p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-3 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-10.svg"
          />
          <h1>17,155</h1>
          <p>Books downloaded from the Translations Project</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-4 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-2020-section-illos-04.svg"
          />
          <h1>86</h1>
          <p>Countries downloading Translations Project books</p>
        </div>
      </div>
      <br />
    </div>
  </div>
)

export default StatsSRTP
