import * as React from "react"

const RobynLetter = () => (
  <div id="president-message" className="row blue">
    <div className="col-12">
      <div className="small-shadow" id="robyn-image"></div>
    </div>

    <div className="row">
      <div className="col-12 col-md-4 col-xl-4">
        <h4>Message from the President and CEO</h4>
        <p>
          Secular Rescue has been in existence since 2015. It was founded in the
          aftermath of the brutal murder by machete-wielding assassins in
          Bangladesh of atheist writer and CFI friend Avijit Roy and the severe
          wounding of his wife and fellow writer Bonya Ahmed.
        </p>
        <p>
          Since then, Secular Rescue has assisted hundreds of atheist activists
          in mostly Muslim-dominated countries. Our helping hand comes in many
          forms, including moving at-risk activists to safer countries and
          providing living expenses, paying for visas and passports, providing
          letters of support for asylum claims, or simply helping closeted
          atheists connect with others for advice and emotional support.
        </p>
        <p>
          Our team of Matt Cravatta and Kat Parker do a tremendous amount with
          the resources we have. (And a huge thank you to Frank Robinson for
          being Secular Rescue’s primary supporter.) But nothing could have
          prepared them for the fall of Kabul and the resulting chaos in
          Afghanistan.
        </p>
        <p>
          Desperate requests came flooding in. Atheists in Afghanistan were
          being targeted by the Taliban through door-to-door searches. People
          were fleeing their homes just ahead of Taliban interrogators and
          execution squads—and some, we know, didn’t make it.
        </p>
        <p>
          CFI Board member and Silicon Valley investor David Cowan was already
          organizing flights out of Afghanistan to save lives and save futures
          for girls wishing to be educated. We joined forces and launched the
          Afghan Rescue Fund to collect funds for this overarching effort.
          Thanks to Cowan and Richard Dawkins, hundreds of thousands of dollars
          were raised and have been put toward airlifts and ground escapes. We
          helped dozens of atheists and other at-risk Afghans escape the
          country, and we are still in the midst of that urgent work.
        </p>
        <p>
          Secular Rescue is truly a life or death program. But CFI was deeply
          involved in another life-or-death endeavor and that, of course, is
          fighting against the misinformation surrounding COVID-19 and
          vaccinations.
        </p>
        <p>
          From the start of the pandemic, CFI has been at the forefront of
          promoting vaccinations as the best possible way for society to emerge
          from the scourge of COVID-19. It has been heartbreaking to watch the
          push for vaccines become so fiercely politicized, so much so that you
          could predict the likelihood of a person being vaccinated based on
          their political affiliation.
        </p>
        <p>
          In state after state, CFI opposed governors and lawmakers who sought
          to outlaw vaccine mandates voluntarily imposed by private businesses
          and universities to keep their campuses and workplaces safe and
          promote general public health. Azhar Majeed, CFI’s director of
          government affairs, kept on top of legislation in statehouses across
          the country. He organized citizen action on a host of bills, including
          those that would grant broad religious exemptions to vaccinations.
          Because no major religious faith objects to vaccinations, the people
          claiming these exemptions were making up their own tenets just to
          avoid getting vaccinated when they were medically able to do so.
        </p>
      </div>
      <div className="col-12 col-md-4 col-xl-4">
        <p>
          Throughout 2021, CFI continued to act as an information gatekeeper,
          posting accurate information on coronavirus interventions and calling
          out quack cures, such as homeopathic coronavirus treatments. On
          multiple occasions, we featured preeminent virologist Dr. Paul Offit
          on our premier webinar series, Skeptical Inquirer Presents, to discuss
          the science behind the COVID-19 vaccines and clear up misconceptions.
        </p>
        <p>
          CFI has been pushing back against baseless conspiracy theories and
          disinformation channels for more than forty years. But who could have
          foreseen a world where the most outlandish and irresponsible voices
          were the most amplified by social media algorithms that care nothing
          for the resulting impacts? Pushing back against this tidal wave of
          conjured, boosted outrage and lies is the battle of our time, and we
          are on the front lines.
        </p>
        <p>
          But it’s not just fighting what we are against. CFI also aggressively
          promotes what we are for.
        </p>
        <p>
          To remind the public of the vital role that science has played in
          bettering and lengthening lives, CFI partnered with the Stiefel
          Freethought Foundation to launch the ScienceSaves campaign. The idea
          was to promote a sense of gratitude for the benefits we all enjoy due
          to science. Julia Hassan, CFI’s multi-channel marketing associate,
          worked to encourage people to post video stories of science benefiting
          their lives. Bertha Vazquez, the director of the Teacher Institute for
          Evolutionary Science (TIES), became CFI’s overall director of
          education, and hit the ground running by launching more than a dozen
          new classroom-ready teaching modules for ScienceSaves. And despite the
          pandemic, TIES adjusted to the new conditions, giving dozens of
          workshops on how to teach evolution in virtual and live presentations.
        </p>
        <p>
          CFI is the only organization in the country that stands at the
          crossroads of secularism and skepticism. We apply critical thinking
          and the scientific method to all claims about the true nature of
          reality, and we let the chips fall where they may. The same standards
          of truth-seeking we apply to a street corner fortune teller we apply
          to claims made by a corner church and a purveyor of healing crystals.
          Our science is not filtered through a tribal lens. It is not dependent
          on your political stripe.
        </p>
        <p>
          That is what makes us stand apart. We stand for science. We stand for
          secularism as the best possible way to order society. And we stand for
          the truth.
        </p>
        <p>Thank you for standing with us.</p>
        <p>
          <br />
          <br />
          With warmest regards,
        </p>
        <img
          id="robyn-sig"
          data-src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/robyn-sig.png"
          className=" lazyloaded"
          src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/robyn-sig.png"
        />
        <p>Robyn Blumner</p>
      </div>
    </div>
  </div>
)
export default RobynLetter
