import * as React from "react"
import { Chart } from "react-google-charts"
import { Accordion } from "react-bootstrap"

export const chartdata = [
  ["Designation", "Dollars"],
  ["Private Donations", 3494972],
  ["Magazine Sales", 723672],
  ["Events", 373151],
  ["Other", 50328],
]

export const chartoptions = {
  backgroundColor: "transparent",
  maintainAspectRatio: false,
  slices: [
    {
      color: "#EA5213",
    },
    {
      color: "#6D2919",
    },
    {
      color: "#B24E2B",
    },
    {
      color: "#EF8160",
    },
  ],
}

export const chartdata2 = [
  ["Designation", "Dollars"],
  ["Programs", 3423431],
  ["Management & General", 502103],
  ["Fundraising", 639041],
]

export const chartoptions2 = {
  backgroundColor: "transparent",
  slices: [
    {
      color: "#084d93",
    },
    {
      color: "#345C7A",
    },
    {
      color: "#498CBF",
    },
    {
      color: "#EF8160",
    },
  ],
}

const SectionDonors = () => (
  <div id="stats-section" className="blue content row">
    <div className="stats-row">
      <div className="orange small-shadow corners col-12 col-xl-6">
        <h4 className="white-text">Center for Inquiry Board of Directors</h4>
        <div className="left">
          <p>
            <strong>Vinod Bhardwaj:</strong> Inventor, entrepreneur
            <br />
            <strong>David Cowan:</strong> Venture capitalist
            <br />
            <strong>Richard Dawkins:</strong> Evolutionary biologist
            <br />
            <strong>Brian Engler:</strong> Operations research analyst,
            nonprofit executive
            <br />
            <strong>Kendrick Frazier:</strong> Editor,{" "}
            <em>Skeptical Inquirer</em>
            <br />
            <strong>Barry Kosmin:</strong> Director of the Institute for the
            Study of Secularism in Society and Culture, Trinity College,
            Hartford, Connecticut
            <br />
            <strong>Bill Maxwell:</strong> Columnist, university professor,
            screenwriter
            <br />
            <strong>Y. Sherry Sheng:</strong> Nonprofit executive, educator
            <br />
            <strong>Julia Sweeney:</strong> Comedian, actress
            <br />
            <strong>Eddie Tabash</strong> (Chair, Board of Directors): Attorney,
            activist
            <br />
            <strong>J. Anderson Thomson</strong> (Vice Chair, Board of
            Directors): Psychiatrist
            <br />
            <strong>Leonard Tramiel:</strong> Physicist, educator
            <br />
          </p>
        </div>
      </div>
    </div>
    <div className="stats-row">
      <div className="lite-grey center small-shadow corners col-12 col-xl-6">
        <h4>Where Your Money Goes</h4>
        <p>
          When you give to the Center for Inquiry and its programs, you want
          your donation to be stewarded with care and attention to the mission.
          That's why we report our revenue and expense ratios here. In addition,
          our Form 990 is available on our website.
        </p>
        <p>
          In 2021, we generated $4,642,123.13 in revenue. Seventy-five percent
          came from private donations, and the remaining came from magazine
          sales, events, and other sources of income.
        </p>
        <div id="react-chart">
          <Chart
            chartType="PieChart"
            data={chartdata}
            options={chartoptions}
            height={"410px"}
            width={"100%"}
          />
        </div>

        <p>
          We are keenly aware of the responsibility we have to our donors when
          it comes to expenditures. This commitment is reflected in our expense
          breakdown:
        </p>
        <Chart
          chartType="PieChart"
          data={chartdata2}
          options={chartoptions2}
          height={"410px"}
          width={"100%"}
        />

        <br />
        <p className="small">
          These figures do not include bequests or support for the CFI
          Development Fund. Please note that these are not final, audited
          figures. We save costs by having our audit done later in the year. If
          you would like to see final, audited figures, please contact the
          Development Department at{" "}
          <a href="mailto:development@centerforinquiry.org" target="_blank">
            development@centerforinquiry.org
          </a>{" "}
          in August.
        </p>
      </div>
    </div>
    <div className="stats-row">
      <div className="lite-grey small-shadow corners col-12 col-xl-6">
        <h4 style={{ textAlign: "center" }}>Fellows and Advisors </h4>
        {/* Honorary Members of the Board of Directors*/}
        <div id="accordion">
          <button
            className="btn btn-link collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="true"
            aria-controls="collapseOne"
          >
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">
                Honorary Members of the Board of Directors
              </h5>
            </div>
          </button>
          <div className="collapse left" id="collapseOne">
            <div className="card-body">
              <ul className="">
                <li>
                  <strong>Rebecca Newberger Goldstein:</strong> Philosopher,
                  novelist
                </li>
                <li>
                  <strong>Susan Jacoby:</strong> Journalist
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Honorary Members of the Board of Directors*/}
        {/* Advisory Board of RDFRS*/}
        <div id="accordion">
          <button
            className="btn btn-link collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">Advisory Board of RDFRS</h5>
            </div>
          </button>
          <div className="collapse left" id="collapseTwo">
            <div className="card-body">
              <ul className="">
                <li>
                  <strong>Woody Kaplan:</strong> Political activist
                </li>
                <li>
                  <strong>Baris Karadogan:</strong> Venture capitalist
                </li>
                <li>
                  <strong>Norman Lear:</strong> Television writer and activist
                </li>
                <li>
                  <strong>Bill Nye:</strong> Science communicator
                </li>
                <li>
                  <strong>Carolyn Porco:</strong> Planetary scientist
                </li>
                <li>
                  <strong>Andrés Roemer:</strong> Diplomat
                </li>
                <li>
                  <strong>Todd Stiefel:</strong> Freethought activist
                </li>
                <li>
                  <strong>Greg Stikeleather:</strong> Serial entrepreneur
                </li>
                <li>
                  <strong>Julia Sweeney:</strong> Actor and playwright
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* Advisory Board of RDFRS*/}
        {/* Fellows of the Committee for Skeptical Inquiry*/}
        <div id="accordion">
          <button
            className="btn btn-link collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="true"
            aria-controls="collapseThree"
          >
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">
                Fellows of the Committee for Skeptical Inquiry
              </h5>
            </div>
          </button>
          <div className="collapse left" id="collapseThree">
            <div className="card-body">
              <ul className="long-list">
                <li>
                  <strong>James E. Alcock*</strong>&nbsp;psychologist, York
                  Univ., Toronto
                </li>
                <li>
                  <strong>Marcia Angell</strong>&nbsp;MD, former
                  editor-in-chief, New England Journal of Medicine
                </li>
                <li>
                  <strong>Kimball Atwood IV</strong>&nbsp;MD, physician, author,
                  Newton, MA
                </li>
                <li>
                  <strong>Banachek</strong>&nbsp;professional magician/mentalist
                  magic consultant/producer
                </li>
                <li>
                  <strong>Stephen Barrett</strong>&nbsp;MD, psychiatrist,
                  author, consumer advocate, Pittsboro, NC
                </li>
                <li>
                  <strong>Robert Bartholomew</strong>&nbsp;sociologist and
                  investigative journalist, Botany College in Auckland, New
                  Zealand.
                </li>
                <li>
                  <strong>Jann Johnson Bellamy</strong>&nbsp;attorney, writer
                  for Science-Based Medicine blog, Tallahassee, FL
                </li>
                <li>
                  <strong>Kenny Biddle</strong>&nbsp;investigator, writer,
                  podcaster, public speaker
                </li>
                <li>
                  <strong>Irving Biederman</strong>&nbsp;psychologist, Univ. of
                  Southern CA
                </li>
                <li>
                  <strong>Sandra Blakeslee</strong>&nbsp;science writer; author;
                  <em>New York Times</em> science correspondent
                </li>
                <li>
                  <strong>Alejandro Borgo</strong> Journalist and writer, editor
                  of <em>Pensar</em> (Argentina)
                </li>
                <li>
                  <strong>Susan Blackmore</strong>&nbsp;visiting lecturer, Univ.
                  of the West of England, Bristol
                </li>
                <li>
                  <strong>Mark Boslough</strong>&nbsp;physicist, Sandia National
                  Laboratories (retired), Albuquerque, New Mexico
                </li>
                <li>
                  <strong>Glenn Branch</strong> Deputy director, National Center
                  for Science Education (United States)
                </li>
                <li>
                  <strong>Henri Broch</strong>&nbsp;physicist, Univ. of Nice,
                  France
                </li>
                <li>
                  <strong>Jan Harold Brunvand</strong>&nbsp;folklorist,
                  professor emeritus of English, Univ. of Utah
                </li>
                <li>
                  <strong>Sean B. Carroll</strong>&nbsp;molecular geneticist,
                  vice president for science education, Howard Hughes Medical
                  Institute, Madison, WI
                </li>
                <li>
                  <strong>Thomas R. Casten</strong>&nbsp;energy expert; founder
                  and chairman, Recycled Energy Development, Westmont, IL
                </li>
                <li>
                  <strong>Timothy Caulfield</strong>&nbsp;professor of health
                  law and policy, University of Alberta, Canada
                </li>
                <li>
                  <strong>K.C. Cole</strong>&nbsp;science writer, author,
                  professor, Univ. of Southern California's Annenberg School of
                  Journalism
                </li>
                <li>
                  <strong>John Cook</strong>&nbsp;author, physicist, George
                  Mason University
                </li>
                <li>
                  <strong>Frederick Crews</strong>&nbsp;literary and cultural
                  critic, professor emeritus of English, Univ. of CA, Berkeley
                </li>
                <li>
                  <strong>Richard Dawkins</strong>&nbsp;zoologist, Oxford Univ.
                </li>
                <li>
                  <strong>Geoffrey Dean</strong>&nbsp;technical editor, Perth,
                  Australia
                </li>
                <li>
                  <strong>Cornelis de Jager</strong>&nbsp;professor of
                  astrophysics, Univ. of Utrecht, the Netherlands
                </li>
                <li>
                  <strong>Daniel C. Dennett</strong>&nbsp;Univ. professor and
                  Austin B. Fletcher Professor of Philosophy, director of Center
                  for Cognitive Studies at Tufts Univ.
                </li>
                <li>
                  <strong>Ann Druyan</strong>&nbsp;writer and producer; CEO,
                  Cosmos Studios, Ithaca, NY
                </li>
                <li>
                  <strong>Sanal Edamaruku</strong>&nbsp;president, Indian
                  Rationalist Association and Rationalist International
                </li>
                <li>
                  <strong>Taner Edis</strong> Professor of physics, Truman State
                  University (United States)
                </li>
                <li>
                  <strong>Mark Edward</strong> Mentalist, skeptic, author
                  (United States)
                </li>

                <li>
                  <strong>Edzard Ernst</strong>&nbsp;professor, Complementary
                  Medicine, Peninsula Medical School, Universities of Exeter and
                  Plymouth, Exeter, UK
                </li>
                <li>
                  <strong>Kenneth Feder</strong>&nbsp;professor of anthropology,
                  Central Connecticut State Univ.
                </li>
                <li>
                  <strong>Krista Federspiel</strong>&nbsp;medical journalist,
                  author, folklorist
                </li>
                <li>
                  <strong>Kevin Folta</strong>&nbsp;molecular biologist,
                  professor and chairman of the Horticultural Sciences
                  Department at the University of Florida
                </li>
                <li>
                  <strong>Barbara Forrest</strong>&nbsp;professor of philosophy,
                  SE Louisiana Univ.
                </li>
                <li>
                  <strong>Craig A. Foster</strong> Professor of psychology, SUNY
                  Cortland (United States)
                </li>
                <li>
                  <strong>Andrew Fraknoi</strong>&nbsp;astronomer, U. of San
                  Francisco
                </li>
                <li>
                  <strong>Kendrick Frazier*</strong>&nbsp;science writer,
                  editor, <em>Skeptical Inquirer</em>
                </li>
                <li>
                  <strong>Christopher C. French</strong>&nbsp;professor,
                  department of psychology, and head of the Anomalistic
                  Psychology Research Unit, Goldsmiths College, Univ. of London
                </li>
                <li>
                  <strong>Julia Galef</strong>&nbsp;writer, podcaster, public
                  speaker
                </li>
                <li>
                  <strong>Luigi Garlaschelli</strong>&nbsp;chemist, Università
                  di Pavia (Italy), research fellow of CICAP, the Italian
                  skeptics group
                </li>
                <li>
                  <strong>Maryanne Garry</strong>&nbsp;professor, School of
                  Psychology, Victoria Univ. of Wellington, New Zealand
                </li>
                <li>
                  <strong>Susan Gerbic</strong>&nbsp;founder and leader of the
                  Guerrilla Skepticism on Wikipedia (GSoW) project
                </li>
                <li>
                  <strong>Thomas Gilovich</strong>&nbsp;psychologist, Cornell
                  Univ.
                </li>
                <li>
                  <strong>David H. Gorski</strong>&nbsp;cancer surgeon and
                  researcher at Barbara Ann Karmanos Cancer Institute and chief
                  of breast surgery section, Wayne State University School of
                  Medicine
                </li>
                <li>
                  <strong>Natalie Grams-Nobmann</strong> Physician, author,
                  former homeopath (Germany)
                </li>
                <li>
                  <strong>David Robert Grimes</strong> Physicist, cancer
                  researcher, broadcaster, author (Ireland)
                </li>
                <li>
                  <strong>Wendy M. Grossman</strong>&nbsp;writer; founder and
                  first editor, <em>The Skeptic</em> magazine (UK)
                </li>
                <li>
                  <strong>Susan Haack</strong>&nbsp;Cooper Senior Scholar in
                  Arts and Sciences, professor of philosophy and professor of
                  Law, Univ. of Miami
                </li>
                <li>
                  <strong>Harriet Hall</strong>&nbsp;MD, family physician,
                  investigator, Puyallup, WA
                </li>
                <li>
                  <strong>Raymond E. Hall</strong> Professor of physics,
                  California State University, Fresno (United States)
                </li>
                <li>
                  <strong>Michael Heap</strong> Clinical and forensic
                  psychologist and lecturer (United Kingdom)
                </li>

                <li>
                  <strong>David J. Helfand</strong>&nbsp;professor of astronomy,
                  Columbia Univ.
                </li>
                <li>
                  <strong>Terence M. Hines</strong>&nbsp;prof. of psychology,
                  Pace Univ., Pleasantville, NY
                </li>
                <li>
                  <strong>Douglas R. Hofstadter</strong>&nbsp;professor of human
                  understanding and cognitive science, Indiana Univ.
                </li>
                <li>
                  <strong>Gerald Holton</strong>&nbsp;Mallinckrodt Professor of
                  Physics and professor of history of science, Harvard Univ.
                </li>
                <li>
                  <strong>Deborah Hyde</strong>&nbsp;folklorist, cultural
                  anthropologist, and editor in chief of the UK-based
                  magazine&nbsp;<em>The Skeptic</em>
                </li>
                <li>
                  <strong>Ray Hyman*</strong>&nbsp;psychologist, Univ. of Oregon
                </li>
                <li>
                  <strong>Stuart D. Jordan</strong>&nbsp;NASA astrophysicist
                  emeritus, science advisor to Center for Inquiry Office of
                  Public Policy, Washington, D.C.
                </li>
                <li>
                  <strong>Barry Karr</strong>&nbsp;executive director, Committee
                  for Skeptical Inquiry, Amherst, New York
                </li>
                <li>
                  <strong>Edwin C. Krupp</strong>&nbsp;astronomer, director,
                  Griffith Observatory, Los Angeles, CA
                </li>
                <li>
                  <strong>Lawrence Kusche</strong>&nbsp;science writer
                </li>
                <li>
                  <strong>Nathan H. Lents</strong> Professor of biology, City
                  University of New York, author of <em>Human Errors</em>{" "}
                  (United States)
                </li>

                <li>
                  <strong>Stephan Lewandowsky</strong>&nbsp;psychologist,
                  researcher, Univ. of Bristol, United Kingdom
                </li>
                <li>
                  <strong>Lin Zixin</strong>&nbsp;former editor,{" "}
                  <em>Science and Technology Daily</em> (China)
                </li>
                <li>
                  <strong>Jere Lipps</strong>&nbsp;Museum of Paleontology, Univ.
                  of CA, Berkeley
                </li>
                <li>
                  <strong>Elizabeth Loftus</strong>&nbsp;professor of
                  psychology, Univ. of CA, Irvine
                </li>
                <li>
                  <strong>William M. London</strong> professor of public health,
                  California State University, Los Angeles
                </li>
                <li>
                  <strong>Leighann Lord</strong> Stand-up comedian, broadcaster,
                  author (United States)
                </li>
                <li>
                  <strong>Daniel Loxton</strong>&nbsp;writer, artist, editor,
                  <em>Skeptic</em> magazine
                </li>
                <li>
                  <strong>Michael E. Mann</strong>&nbsp;distinguished Professor
                  of Atmospheric Sciences and director of the Earth Systems
                  Sciences Center at the Pennsylvania State University
                </li>
                <li>
                  <strong>David Marks</strong>&nbsp;psychologist, City Univ.,
                  London
                </li>
                <li>
                  <strong>Michael Marshall</strong> investigator, activist,
                  podcaster, and editor of <em>The Skeptic</em> (UK) magazine
                  (United Kingdom)
                </li>
                <li>
                  <strong>Mario Mendez-Acosta</strong>&nbsp;journalist and
                  science writer, Mexico City
                </li>
                <li>
                  <strong>Kenneth R. Miller</strong>&nbsp;professor of biology,
                  Brown Univ.
                </li>
                <li>
                  <strong>Tim Mendham</strong> Executive officer of Australian
                  Skeptics and editor of <em>The Skeptic </em>(Australia)
                </li>

                <li>
                  <strong>David Morrison</strong>&nbsp;space scientist, NASA
                  Ames Research Center
                </li>
                <li>
                  <strong>Richard A. Muller</strong>&nbsp;professor of physics,
                  Univ. of CA, Berkeley
                </li>
                <li>
                  <strong>Joe Nickell</strong>&nbsp;senior research fellow, CSI
                </li>
                <li>
                  <strong>Jan Willem Nienhuys</strong>&nbsp;mathematician,
                  Waalre, The Netherlands
                </li>
                <li>
                  <strong>Lee Nisbet</strong>&nbsp;philosopher, Medaille College
                </li>
                <li>
                  <strong>Matthew C. Nisbet</strong> professor of communication,
                  public policy, and public affairs, Northeastern University,
                  Boston
                </li>
                <li>
                  <strong>Steven Novella</strong>&nbsp;MD, assistant professor
                  of neurology, Yale Univ. School of Medicine
                </li>
                <li>
                  <strong>Bill Nye</strong>&nbsp;science educator and television
                  host, Nye Labs
                </li>
                <li>
                  <strong>James E. Oberg</strong>&nbsp;science writer
                </li>
                <li>
                  <strong>Paul Offit</strong>&nbsp;doctor, author, researcher,
                  professor, Univ. of Pennsylvania
                </li>
                <li>
                  <strong>Naomi Oreskes</strong>&nbsp;geologist, science
                  historian, professor, Harvard University
                </li>
                <li>
                  <strong>Loren Pankratz</strong>&nbsp;psychologist, Oregon
                  Health Sciences Univ.
                </li>
                <li>
                  <strong>Jay M. Pasachoff</strong>&nbsp;Field Memorial
                  Professor of Astronomy and director of the Hopkins
                  Observatory, Williams College
                </li>
                <li>
                  <strong>Natalia Pasternak</strong> microbiologist, writer, and
                  president, Instituto Questão de Ciência, São Paulo, Brazil
                </li>
                <li>
                  <strong>John Paulos</strong>&nbsp;mathematician, Temple Univ.
                </li>
                <li>
                  <strong>Clifford A. Pickover</strong>&nbsp;scientist, author,
                  editor, IBM T.J. Watson Research Center
                </li>
                <li>
                  <strong>Massimo Pigliucci</strong>&nbsp;professor of
                  philosophy, City Univ. of New York-Lehman College
                </li>
                <li>
                  <strong>Steven Pinker</strong>&nbsp;cognitive scientist,
                  Harvard Univ.
                </li>
                <li>
                  <strong>Massimo Polidoro</strong>&nbsp;science writer, author,
                  executive director of CICAP, Italy
                </li>
                <li>
                  <strong>James L Powell</strong>&nbsp;geochemist, author,
                  executive director, National Physical Science Consortium
                </li>
                <li>
                  <strong>Anthony R. Pratkanis</strong>&nbsp;professor of
                  psychology, Univ. of California, Santa Cruz
                </li>
                <li>
                  <strong>Donald R. Prothero</strong>&nbsp;paleontologist,
                  geologist, author, National History Museum of Los Angeles
                  County
                </li>
                <li>
                  <strong>Benjamin Radford</strong>&nbsp;investigator; research
                  fellow, Committee for Skeptical Inquiry
                </li>
                <li>
                  <strong>Amardeo Sarma*</strong>&nbsp;chairman, GWUP, Germany
                </li>
                <li>
                  <strong>Richard Saunders</strong>&nbsp;Life Member of
                  Australian Skeptics; educator; investigator; podcaster;
                  Sydney, Australia
                </li>
                <li>
                  <strong>Joe Schwarcz</strong>&nbsp;director, McGill Office for
                  Science and Society
                </li>
                <li>
                  <strong>Eugenie C. Scott*</strong>&nbsp;physical
                  anthropologist, former executive director (retired), National
                  Center for Science Education
                </li>
                <li>
                  <strong>Seth Shostak</strong>&nbsp;senior astronomer, SETI
                  Institute, Mountain View, CA
                </li>
                <li>
                  <strong>Simon Singh</strong>&nbsp;science writer; broadcaster;
                  UK
                </li>
                <li>
                  <strong>Dick Smith</strong>&nbsp;film producer, publisher,
                  Terrey Hills, N.S.W., Australia
                </li>
                <li>
                  <strong>Keith E. Stanovich</strong>&nbsp;cognitive
                  psychologist; professor of human development and applied
                  psychology, Univ. of Toronto
                </li>
                <li>
                  <strong>Karen Stollznow</strong>&nbsp;linguist; skeptical
                  investigator; writer; podcaster
                </li>
                <li>
                  <strong>Jill Cornell Tarter</strong>&nbsp;astronomer, SETI
                  Institute, Mountain View, CA
                </li>
                <li>
                  <strong>Carol Tavris</strong>&nbsp;psychologist and author,
                  Los Angeles, CA
                </li>
                <li>
                  <strong>David E. Thomas</strong>&nbsp;physicist and
                  mathematician, Peralta, NM
                </li>
                <li>
                  <strong>Neil deGrasse Tyson</strong>&nbsp;astrophysicist and
                  director, Hayden Planetarium, New York City
                </li>
                <li>
                  <strong>Leonard Tramiel</strong> Physicist, science
                  communicator, computing pioneer (United States)
                </li>
                <li>
                  <strong>James Underdown</strong> writer, investigator, founder
                  of Center for Inquiry Investigations Group (CFIIG), Los
                  Angeles
                </li>
                <li>
                  <strong>Joseph Uscinski</strong> political scientist,
                  University of Miami
                </li>
                <li>
                  <strong>Bertha Vazquez</strong> science teacher, director of
                  the Teacher Institute for Evolutionary Science (TIES)
                </li>
                <li>
                  <strong>Indre Viskontas</strong>&nbsp;cognitive
                  neuroscientist, tv and podcast host, and opera singer, San
                  Francisco, CA
                </li>
                <li>
                  <strong>Marilyn vos Savant</strong>&nbsp;<em>Parade</em>{" "}
                  magazine contributing editor
                </li>
                <li>
                  <strong>Stuart Vyse*</strong>&nbsp;psychologist, professor,
                  author
                </li>
                <li>
                  <strong>Steven Weinberg</strong>&nbsp;professor of physics and
                  astronomy, Univ. of Texas at Austin; Nobel laureate
                </li>
                <li>
                  <strong>Mick West</strong> writer, podcaster, investigator,
                  debunker, Folsom, CA
                </li>
                <li>
                  <strong>E.O. Wilson</strong>&nbsp;Univ. professor emeritus,
                  organismic and evolutionary biology, Harvard Univ.
                </li>
                <li>
                  <strong>Richard Wiseman</strong>&nbsp;psychologist, Univ. of
                  Hertfordshire, England
                </li>
                <li>
                  <strong>Benjamin Wolozin</strong>&nbsp;professor, department
                  of pharmacology, Boston Univ. School of Medicine
                </li>
              </ul>
              <p>
                <strong>*</strong>{" "}
                <em>denotes member of CSI Executive Council</em>
              </p>
            </div>
          </div>
        </div>
        {/* Fellows of the Committee for Skeptical Inquiry*/}
        {/* CSI*/}
        <div id="accordion">
          <button
            className="btn btn-link collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseFour"
            aria-expanded="true"
            aria-controls="collapseFour"
          >
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">CSI Scientific and Technical Consultants</h5>
            </div>
          </button>
          <div className="collapse left" id="collapseFour">
            <div className="card-body">
              <ul className="long-list">
                <li>Gary Bauslaugh</li>
                <li>Richard E. Berendzen</li>
                <li>Martin Bridgstock</li>
                <li>Richard Busch</li>
                <li>Shawn Carlson</li>
                <li>Roger Culver</li>
                <li>Felix Ares de Blas</li>
                <li>Nahum Duker</li>
                <li>Taner Edis</li>
                <li>Barbara Eisenstadt</li>
                <li>William Evans</li>
                <li>Bryan Farha</li>
                <li>John F. Fischer</li>
                <li>Eileen Gambrill</li>
                <li>Luis Alfonso Gamez</li>
                <li>Sylvio Garattini</li>
                <li>Laurie Godfrey</li>
                <li>Gerald Goldin</li>
                <li>Donald Goldsmith</li>
                <li>Alan Hale</li>
                <li>Clyde Herreid</li>
                <li>Gábor Hraskó</li>
                <li>Michael Hutchinson</li>
                <li>Philip A. Ianna</li>
                <li>W. Kelly</li>
                <li>Richard H. Lange</li>
                <li>Bernard J. Leikind</li>
                <li>Rebecca Long</li>
                <li>John Mashey</li>
                <li>Thomas R. McDonough</li>
                <li>James E. McGaha</li>
                <li>Joel A. Moskowitz</li>
                <li>Julia Offe</li>
                <li>John W. Patterson</li>
                <li>James R. Pomerantz</li>
                <li>Tim Printy</li>
                <li>Daisie Radner</li>
                <li>Robert H. Romer</li>
                <li>Karl Sabbagh</li>
                <li>Robert J. Samp</li>
                <li>Steven D. Schafersman</li>
                <li>Chris Scott</li>
                <li>Stuart D. Scott Jr.</li>
                <li>Erwin M. Segal</li>
                <li>Carla Selby</li>
                <li>Steven N. Shore</li>
                <li>Waclaw Szybalski</li>
                <li>Sarah G. Thomason</li>
                <li>Tim Trachet</li>
                <li>David Willey</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* CSI*/}

    <div className="stats-row">
      <div className="orange small-shadow corners col-12 col-xl-6">
        <h4 className="white-text">Donors</h4>
        <p>
          None of our work would be possible without the steadfast support of
          our donors and subscribers. We are very grateful for their generosity.
        </p>
        {/* New Button */}
        <div id="donors">
          <button
            className="btn btn-link collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseExample"
            aria-expanded="true"
            aria-controls="collapseExample"
          >
            <div className="card-header" id="headingOne">
              <h5 className="mb-0 white-text">Show Donors</h5>
            </div>
          </button>
          <div className="collapse left" id="collapseExample">
            <div className="card-body">
              <h4 className="white-text">$200,000 +</h4>
              <ul className="white-text">
                <li>The Estate of Karen Brandt</li>
                <li>The Estate of Thomas Flynn</li>
                <li>The Estate of Warren G. Tisdale</li>
                <li>The Estate of Lois H. Ward</li>
                <li>The Estate of James A. Wilkinson</li>
                <li>Abbaszadeh Foundation</li>
                <li>Cowan Family Foundation</li>
                <li>Margery Martin Trust</li>
                <li>Stiefel Freethought Foundation</li>
              </ul>
              <h4 className="white-text">$100,000 - $199,999</h4>
              <ul className="white-text">
                <li>Jeff C. Hawkins and Janet L. Strauss</li>
                <li>Maurice Meslans</li>
                <li>The Estate of Paul B. Shively</li>
                <li>Edward Tabash</li>
                <li>The Estate of Alan J. Talbert</li>
                <li>Frank S. Robinson Enlightenment Fund</li>
                <li>Anonymous</li>
              </ul>
              <h4 className="white-text">$25,000 - $99,999</h4>
              <ul className="white-text">
                <li>Sally Alger</li>
                <li>Ralph and Regina Anavy</li>
                <li>Lawrence I. Bonchek</li>
                <li>The Estate of Werner M. Brandenberg</li>
                <li>Edward D. Brown</li>
                <li>Richard Dawkins</li>
                <li>Julian Haydon</li>
                <li>Noah Lee</li>
                <li>Adavee Roebuck</li>
                <li>Thomas M. Scruggs</li>
                <li>Sherry Sheng and Spike Wadsworth</li>
                <li>Leonard J. Tramiel</li>
                <li>The Estate of Theodore M. Utchen</li>
                <li>DEW Foundation</li>
                <li>James Hervey Johnson Charitable Trust</li>
                <li>Louis J. Appignani Foundation</li>
                <li>Quitiplas Foundation</li>
                <li>Starting Line Foundation</li>
              </ul>
              <h4 className="white-text">$10,000 - $24,999</h4>
              <ul className="white-text long-list">
                <li>Stephen J. Barrett</li>
                <li>Brenton Buxton</li>
                <li>Thomas and Patricia Canfield</li>
                <li>David Carter</li>
                <li>Patrick R. Denker</li>
                <li>Robert H. Dickerson</li>
                <li>Brian D. Engler</li>
                <li>John F. Estes, III and Norma L. Horner</li>
                <li>Adam Fisher</li>
                <li>Robert T. Foley</li>
                <li>Peter and Pamela Freyd</li>
                <li>The Estate of Rosamund D. Gruner</li>
                <li>Douglas Harris</li>
                <li>Richard Hermsen</li>
                <li>Scott Hunter</li>
                <li>Patsy and Tom Inglet</li>
                <li>Everett N. Jones</li>
                <li>Earl L. Marble and William E. Mastrocola</li>
                <li>Michael H. McCarron</li>
                <li>John Osberg</li>
                <li>Daniel Pike</li>
                <li>Kenneth R. Powell</li>
                <li>Harold and Doreen Saferstein</li>
                <li>The Estate of Herbert G. Schapiro</li>
                <li>Forrest Shepard</li>
                <li>Greg and Belle Stikeleather</li>
                <li>Warren and Michelle Stine</li>
                <li>Paul Storey</li>
                <li>Julia Sweeney</li>
                <li>Paul Tustain</li>
                <li>Stephen and Diane Uhl</li>
                <li>The Estate of Janet L. Wages</li>
                <li>MacDonald-Peterson Foundation</li>
                <li>Sheila, Dave and Sherry Gold Foundation</li>
                <li>Stephen and Julie Maas Foundation</li>
                <li>Anonymous (4)</li>
              </ul>
              <h4 className="white-text">$5,000 - $9,999</h4>
              <ul className="white-text long-list">
                <li>James J. Brokaw</li>
                <li>Edward and Diane Buckner</li>
                <li>Nancy G. Frakes</li>
                <li>Sylvia R. Gallagher</li>
                <li>Joseph Giganti</li>
                <li>Jeanne Goldberg</li>
                <li>Joseph M. Heery</li>
                <li>Martin J&auml;ssing</li>
                <li>Larry D. King</li>
                <li>Douglas W. Kinney</li>
                <li>Mark L. Kolodziej</li>
                <li>Daniel Mendez</li>
                <li>John Mosley and Barbara Foster</li>
                <li>Misha Novini</li>
                <li>Joan O'Brien</li>
                <li>Alan B. Palmer</li>
                <li>Henry Pena</li>
                <li>Robert Renka</li>
                <li>Thomas A. Savignano</li>
                <li>Herb Silverman and Sharon Fratepietro</li>
                <li>Richard W. Smith</li>
                <li>Robert B. Stern</li>
                <li>The Estate of Wayne Wheeler</li>
                <li>Frederik and Cynthia Winsser</li>
                <li>Jenna Hughes Foundation</li>
                <li>Kelly Foundation</li>
                <li>Anonymous (2)</li>
              </ul>
              <h4 className="white-text">$2,500 - $4,999</h4>
              <ul className="white-text long-list">
                <li>James M. Alexander</li>
                <li>Harry F. Baker</li>
                <li>Robert P. Balles</li>
                <li>Robyn Blumner and Damian Cristodero</li>
                <li>Susan Burrows</li>
                <li>Jerri L. Campbell</li>
                <li>Robert D. Carl, III</li>
                <li>Kenneth Deaton</li>
                <li>James D. Ewing</li>
                <li>Jeffrey T. Haley</li>
                <li>James A. Hamilton</li>
                <li>David L. Henehan</li>
                <li>Justin Hinckley</li>
                <li>Steve Hopkins</li>
                <li>Don D. Kang</li>
                <li>Gary L. Kaplan</li>
                <li>James T. Kirk</li>
                <li>Michael Kirkpatrick</li>
                <li>Les LaZar</li>
                <li>Mark Lloyd</li>
                <li>Walter and Karen Loewenstern</li>
                <li>Dennis and Donna Monson</li>
                <li>Richard J. Norton</li>
                <li>William S. Nye</li>
                <li>David O'Brien</li>
                <li>Sean and Diana O'Brien</li>
                <li>Michael Philips</li>
                <li>Annmarie Sasdi</li>
                <li>Daniel J. Schultheisz</li>
                <li>Barry P. Skeist</li>
                <li>Randolph and Margaret Strautman</li>
                <li>David A. Terret</li>
                <li>Keith Thobe</li>
                <li>Sandra M. Turner</li>
                <li>Doug Weaver</li>
                <li>Robert and Blaikie Worth</li>
                <li>Pete Lins and Argie O'Shea Charitable Fund</li>
                <li>Anonymous</li>
              </ul>
              <h4 className="white-text">$1,000 - $2,499</h4>
              <ul className="white-text long-list">
                <li>Roger Allec</li>
                <li>William and Rosie Ameen</li>
                <li>William T. Amneus</li>
                <li>Brian Arbogast and Valerie Tarico</li>
                <li>Mark and Carrie Asplund</li>
                <li>William L. Atkinson</li>
                <li>Mark D. Barnes</li>
                <li>Matthew R. Barron</li>
                <li>Donna and Dan Barski</li>
                <li>Brian Bartholmai and Amanda Ebright</li>
                <li>Henry L. Bass</li>
                <li>Loren D. Bauman</li>
                <li>Keith Beckley</li>
                <li>Jann and Raymond Bellamy</li>
                <li>Larry and Kathryn Berkbigler</li>
                <li>Fred A. Berry</li>
                <li>Lee Blumner</li>
                <li>Craig B. Bohren</li>
                <li>Robert Boomsliter</li>
                <li>Stewart Bornhoft and Stephen McNabb</li>
                <li>Brent Bowen</li>
                <li>Richard A. Brandshaft</li>
                <li>Steve Bratteng</li>
                <li>Roger A. Brewin</li>
                <li>Michael M. Brodie</li>
                <li>Bruce T. Burton</li>
                <li>Steve Campbell</li>
                <li>Carlo Carmona</li>
                <li>George M. Carnahan</li>
                <li>Thomas and Judith Casten</li>
                <li>Agata Celmerowski</li>
                <li>Robert Cirillo</li>
                <li>Barbara and Gordon Clark</li>
                <li>Jay Clifton</li>
                <li>William F. Coby</li>
                <li>Don Collins and Sally Epstein</li>
                <li>Robert and Sherron Collins</li>
                <li>Robert A. Connor</li>
                <li>John and Joanne Daniels</li>
                <li>Florence Davidson</li>
                <li>Jack and Janice DeBaun</li>
                <li>Naomi Diamond</li>
                <li>Arno Driedger</li>
                <li>Norman Dudziak</li>
                <li>Chopper A. Eckhoff</li>
                <li>Keith Elkin</li>
                <li>Winfred S. Emmons</li>
                <li>Lesley Ernst</li>
                <li>William D. Evers</li>
                <li>James Eyman</li>
                <li>Kendrick and Ruth Frazier</li>
                <li>Robert Gantt</li>
                <li>Glenn Gaunt</li>
                <li>Dave Gendler</li>
                <li>David J. George</li>
                <li>Ronald C. Gibson</li>
                <li>Steve S. Golik</li>
                <li>Cody Gorden</li>
                <li>Franco Gotte</li>
                <li>Raymond J. Goulart</li>
                <li>Frank C. Hachman</li>
                <li>Jon Hagler</li>
                <li>David Hall and Marjorie Devereaux</li>
                <li>Roy M. Havenhill</li>
                <li>Mary and Paul Hazelton</li>
                <li>Theodore Hazlett</li>
                <li>Dale and Ann Heatherington</li>
                <li>Paul and Margaret Heffron</li>
                <li>Jerald and Pam Himes</li>
                <li>Ahmed Hindawi</li>
                <li>Frederick G. Hoeptner</li>
                <li>Donald E. Hostetler</li>
                <li>Pamela C. House</li>
                <li>Phillip L. Howard</li>
                <li>Szu Wen Huang</li>
                <li>Franklin Hull</li>
                <li>David Humphreys</li>
                <li>A. N. Johnson</li>
                <li>Eric and Nancy Johnson</li>
                <li>Warford B. Johnson</li>
                <li>Harold Johnston</li>
                <li>Jeff L. Jones and Scott Trout</li>
                <li>Abhir Joshi</li>
                <li>Cassandra G. and I. Richard Junk</li>
                <li>Tim Kaiser</li>
                <li>Baris Karadogan</li>
                <li>Norman E. Kelker</li>
                <li>Markus Kessler</li>
                <li>James Kjar</li>
                <li>Barry A. Kosmin</li>
                <li>Judd J. Kramarcik</li>
                <li>Robert and Susan Kresek</li>
                <li>Timothy J. LaBerge</li>
                <li>Richard Landsman</li>
                <li>Patricia Lange</li>
                <li>Richard Lell</li>
                <li>Robert and Karen Levin</li>
                <li>Ronald A. Lindsay</li>
                <li>Jonathan W. Lutton</li>
                <li>John E. Lutz</li>
                <li>Lois and Hayes Manning</li>
                <li>Elisabeth Mannschott</li>
                <li>James G. Martin</li>
                <li>William J. Martinez</li>
                <li>Robert I. Masta</li>
                <li>Herbert A. Masters</li>
                <li>Walter and Nancy McClure</li>
                <li>Frances S. McKenzie</li>
                <li>David Meeker</li>
                <li>Murray Meisels</li>
                <li>Edward Miguel</li>
                <li>Alyssa Milano</li>
                <li>Greg Miller</li>
                <li>Kevin J. Miller and Melanie Sharp</li>
                <li>Mark Mitten</li>
                <li>Scot Morris</li>
                <li>David Morrison</li>
                <li>Brian F. Moynihan</li>
                <li>Leigh W. Murray and David W. Smith</li>
                <li>John Nedby</li>
                <li>Erik A. Nelson</li>
                <li>Paul Newman</li>
                <li>Glen Nicholson and Roxanne Yanishewski</li>
                <li>Hugh Nile</li>
                <li>Nancy Norton-Taylor</li>
                <li>Fred Pollack</li>
                <li>Glenn Pransky and Terry Snyder</li>
                <li>John Ramirez</li>
                <li>Frederic Rich</li>
                <li>William F. Ring</li>
                <li>Mark L. Rockoff</li>
                <li>Bruce Rockwell</li>
                <li>Sergio E. Rosales Wybo</li>
                <li>Adam R. Rose</li>
                <li>Peter Rosmarin</li>
                <li>Robert F. Russell</li>
                <li>Paul and Terry Ryan</li>
                <li>Thomas Ryll</li>
                <li>Joel H. Sanders</li>
                <li>James C. Sanford</li>
                <li>Barbara Sannwald</li>
                <li>Edwin G. Sather</li>
                <li>Tyler Schaal</li>
                <li>Ellery Schempp</li>
                <li>Steven Schmitt</li>
                <li>Philip R. Scholly</li>
                <li>Jerald S. Schwarz</li>
                <li>Jefferson and Catherine Seaver</li>
                <li>Peter Sherrod</li>
                <li>Wanda Shirk</li>
                <li>Charles H. Shultz</li>
                <li>Bruce G. Sicklesteel</li>
                <li>John C. Simpson</li>
                <li>Frederick and Priscilla Smith</li>
                <li>Mark Smith</li>
                <li>David and Elizabeth Snoeyenbos</li>
                <li>Lawrence Stifler</li>
                <li>Max Stolz Jr.</li>
                <li>John P. Sullivan</li>
                <li>Grant Taylor</li>
                <li>Thomas B. Thomas</li>
                <li>James A. Thomson</li>
                <li>Frank Tobin and Ilene M. Chester</li>
                <li>Merri K. Tully and Helen Levine</li>
                <li>Theodore A. Tyler</li>
                <li>Stan Ulrich</li>
                <li>Charles S. Van Ark</li>
                <li>Bayard T. Van Hecke</li>
                <li>Bruce Van Natta</li>
                <li>William S. Verdell</li>
                <li>Ron and Ellen Vilas</li>
                <li>Walter vom Saal</li>
                <li>William and Janet Wagner</li>
                <li>Mary E. Wais</li>
                <li>David Wallace</li>
                <li>Bradford B. Walters</li>
                <li>Glenn A. Waychunas</li>
                <li>Stein Weissenberger</li>
                <li>Thomas and Valerie Wheeler</li>
                <li>G. Warren Whitaker</li>
                <li>Gary Whittenberger</li>
                <li>Beverly Wickstrom</li>
                <li>Karl Wiegers and Christine Zambito</li>
                <li>Michael Wiese</li>
                <li>Louise Wilde</li>
                <li>Philip Wyatt</li>
                <li>Craig R. Wyss</li>
                <li>Agents for Critical Thinking</li>
                <li>Angela and Bill Rowe Charitable Fund</li>
                <li>Coastal Community Foundation of South Carolina</li>
                <li>Community Foundation for Northern Virginia Inc</li>
                <li>David C. and Mark E. Cunningham Fund</li>
                <li>Hemphill Family Foundation</li>
                <li>Klein Family Foundation</li>
                <li>Network for Good</li>
                <li>Vertex Pharmaceuticals</li>
                <li>Anonymous</li>
              </ul>
              <h4 className="white-text">$500 - $999</h4>
              <ul className="white-text long-list">
                <li>Robert B. Abel</li>
                <li>Daniel Alonso</li>
                <li>Daniel P. Anderson</li>
                <li>Marcia Angell</li>
                <li>Paul Angelo</li>
                <li>Serge Ansay</li>
                <li>Peter Armstrong</li>
                <li>Siegfried Arneitz</li>
                <li>Lisa Bambauer</li>
                <li>Steven J. Banilower</li>
                <li>T. Peter and Lynn Barbur</li>
                <li>Erik Barthel</li>
                <li>Todd Barton</li>
                <li>Michael Beaufeaux</li>
                <li>Alan C. Bedell</li>
                <li>John Beghin</li>
                <li>Jeffrey B. Black</li>
                <li>Alan Blush</li>
                <li>Charles S. Bouril</li>
                <li>Peter and Margaret Boyer</li>
                <li>David A. Brashear</li>
                <li>Philippe A. Briandet</li>
                <li>Jace Browning</li>
                <li>Stephen J. Bruun</li>
                <li>Philip A. Bryan</li>
                <li>Sergio Buccilli</li>
                <li>Henry Bugatto</li>
                <li>Richard Burns</li>
                <li>Paul Bursic</li>
                <li>John W. Byrom</li>
                <li>David R. Bywaters</li>
                <li>Katherine Carmichael and Carol Hedtcke</li>
                <li>Patrick V. Casali</li>
                <li>D. Lamar and Evelyn Cason</li>
                <li>Holly Cechvala</li>
                <li>Andrew Chong</li>
                <li>Stephen Clark</li>
                <li>John Cooper</li>
                <li>Peter Cooper</li>
                <li>Jeffrey Cottrell</li>
                <li>Guy T. Craig</li>
                <li>James Crenner</li>
                <li>Franklin P. Crownover</li>
                <li>Charles A. Culman</li>
                <li>Robert Cutshall</li>
                <li>Robert Daniell</li>
                <li>Karsten de Braaf</li>
                <li>Betty L. Desbiens</li>
                <li>Michael DeWeert</li>
                <li>Michael J. Dolan</li>
                <li>Michael Donikian</li>
                <li>Paul C. Donohue</li>
                <li>Virginia Downes</li>
                <li>Steven and Susan Duerr</li>
                <li>Mark R. Dusbabek</li>
                <li>Eleanor G. Dwight</li>
                <li>Larry and Judy East</li>
                <li>Eugene V. Edelstein</li>
                <li>J. David Eisenberg</li>
                <li>Tarik El-Bakri</li>
                <li>Thomas W. Ethington</li>
                <li>Robert J. Fassbender</li>
                <li>Howard R. Feldman</li>
                <li>Andrew Ferstl</li>
                <li>Robert Field</li>
                <li>Elaine S. Fieldman</li>
                <li>Jeffry A. Forsythe</li>
                <li>Nigel J. Foster</li>
                <li>Robert Fritsch</li>
                <li>David Gaston</li>
                <li>Thomas W. Gedra</li>
                <li>Thomas H. Getz</li>
                <li>Louis Giglio</li>
                <li>Jeanne Gitzen</li>
                <li>Peter Glickman</li>
                <li>Edward and Elizabeth Goff</li>
                <li>Marc Goldenberg</li>
                <li>Harvey and Betty Gossard</li>
                <li>David Gould</li>
                <li>Christine F. Grassman</li>
                <li>George and Beth Gray</li>
                <li>Wayne and Deborah Gray</li>
                <li>Jonathan Greenlaw</li>
                <li>James D. Griffin</li>
                <li>Gordon Grosscup</li>
                <li>Darryl Gwynne</li>
                <li>Oliver Halle</li>
                <li>William Hammett</li>
                <li>John C. Hancock</li>
                <li>John A. Hardy</li>
                <li>Alan W. Harris</li>
                <li>John Harris</li>
                <li>Kenneth Harris</li>
                <li>Gale and Dennis Hatch</li>
                <li>John Hayes</li>
                <li>David J. Helfand</li>
                <li>Robert Hemphill</li>
                <li>Ron Herman</li>
                <li>Randall L. Hilscher</li>
                <li>John Hirschi</li>
                <li>Cheryl and Ken Hofbauer</li>
                <li>Scott Holme</li>
                <li>Dennis L. Hoofnagle</li>
                <li>Scott Hummel</li>
                <li>Robert D. Hunt</li>
                <li>Brooke M. Hunter</li>
                <li>Jennifer Jackson and Wolfgang Sturhahn</li>
                <li>Edward Jervey</li>
                <li>Lawrence L. Johnson</li>
                <li>Michael Johnson</li>
                <li>Susan K. Johnson</li>
                <li>Evelyn Johnstone</li>
                <li>Ann F. Kah</li>
                <li>Richard A. Kaslow</li>
                <li>Michael Kaufman</li>
                <li>Keith Kelly</li>
                <li>Eric L. Kemmler</li>
                <li>Stephen J. Kern</li>
                <li>Jeff T. King</li>
                <li>Richard R. and Sally B. Kinsey</li>
                <li>Deborah Kirby</li>
                <li>Fritz Klein</li>
                <li>James Kleinrath</li>
                <li>Kurt Klingman</li>
                <li>Edward Kort and Margaret DeCicco</li>
                <li>Chase Krumins</li>
                <li>William S. Laitner</li>
                <li>Willie B. Lamouse-Smith</li>
                <li>Raymond Lebowski</li>
                <li>Edwin and Judith Leonard</li>
                <li>Ilya Levental</li>
                <li>Gerald Lipsky and Carol Rouleau</li>
                <li>John P. Litrio</li>
                <li>Bret Llewellyn</li>
                <li>Edward J. Logue</li>
                <li>Bruce E. Lohman</li>
                <li>William M. London</li>
                <li>John P. Loveridge</li>
                <li>Steven C. Lowe</li>
                <li>James Lull</li>
                <li>Albert Macovski</li>
                <li>Susan L. Malasics</li>
                <li>Mitchell Margolis</li>
                <li>Manuel Mari</li>
                <li>David W. Marshall</li>
                <li>John Martonchik</li>
                <li>William Mascioli</li>
                <li>Alan Mattfeld</li>
                <li>Donald and Mary McKenzie</li>
                <li>George G. McKinney</li>
                <li>Gloria and Robert McMillon</li>
                <li>Daniel McMullen</li>
                <li>Joe McNeal</li>
                <li>Phillip Meade</li>
                <li>Andrew Mehler</li>
                <li>David D. Meisel</li>
                <li>J. T. Menaker</li>
                <li>John R. Menninger</li>
                <li>Alan and Carolyn Meyer</li>
                <li>Charles Miller</li>
                <li>Guy M. Miller</li>
                <li>Robert Miller</li>
                <li>Andrew C. Milman</li>
                <li>James Moore</li>
                <li>Steven D. Moore</li>
                <li>David L. Morrow</li>
                <li>Gerald Moshofsky</li>
                <li>James Mueller</li>
                <li>Michael E. Murphy</li>
                <li>David L. Myers</li>
                <li>J. Alan Myers</li>
                <li>Bruce Naylor</li>
                <li>Douglas M. Neuse</li>
                <li>Victor Nichols</li>
                <li>Robert Nolan and Scott Tuff</li>
                <li>Wilfred G. Norris</li>
                <li>Mark Nudelman</li>
                <li>John M. O'Connor</li>
                <li>David O'Donnell</li>
                <li>Paul A. Offit</li>
                <li>Andrew M. Olson</li>
                <li>Ron Parnell</li>
                <li>Deborah Pate</li>
                <li>Bobby Patton</li>
                <li>Karl E. Pearson</li>
                <li>Brian Perez</li>
                <li>Kathryn Petersen</li>
                <li>Daniel Phillips</li>
                <li>Phyllis L. Pirie</li>
                <li>Thomas and Halina Platt</li>
                <li>David and Jacqueline Pleet</li>
                <li>Jerome B. Posner</li>
                <li>Regie and David Powell</li>
                <li>Dana Price</li>
                <li>Janet Rajadhyaksha</li>
                <li>Rita H. Rausch</li>
                <li>Eric Rhoads</li>
                <li>Harry E. Rice</li>
                <li>George H. Richmond</li>
                <li>Frank Rodrick</li>
                <li>Hewitt Rose, III and Diane M. Rose</li>
                <li>Michael J. Rosenthal</li>
                <li>Joseph Ross</li>
                <li>Bruce N. Rowen</li>
                <li>Robert and Louise Rozanski</li>
                <li>Milton Russell</li>
                <li>Michael Sagurton</li>
                <li>Chris O. Sanders</li>
                <li>Bob N. Scholl</li>
                <li>James D. Schreiber</li>
                <li>Adam L. Schwartz</li>
                <li>Justin Seamons</li>
                <li>Brahm and Stephanie Segal</li>
                <li>Jerome Shedd</li>
                <li>Thomas A. Shipka</li>
                <li>Dean Shoffeitt</li>
                <li>Calvin Siemer</li>
                <li>Marc and Lisa Sigle</li>
                <li>Todd and Betiana Simon</li>
                <li>Jack D. Simpson</li>
                <li>Nuala J. Sinisi</li>
                <li>Steven Skalet</li>
                <li>Lane Skeeters</li>
                <li>Bryan Smiley</li>
                <li>Doug K. Smith</li>
                <li>Frank and April Smith</li>
                <li>Diane Snyder</li>
                <li>Irwin and Martha Spiegelman</li>
                <li>Lowri Sprung</li>
                <li>John States</li>
                <li>Phillips Stevens</li>
                <li>Debra Stikes</li>
                <li>Alan Stoebig</li>
                <li>Daniel Strack</li>
                <li>Jon Strand</li>
                <li>Steven S. Strongin</li>
                <li>Rhea Talley</li>
                <li>James S. Thompson</li>
                <li>Fred Thorlin</li>
                <li>Edward Todd</li>
                <li>Jon Topp</li>
                <li>Henry Townsend</li>
                <li>Peter Tripician</li>
                <li>Richard Tubman and Margaret Burns</li>
                <li>Mary C. Ubatuba</li>
                <li>Edward R. Uehling</li>
                <li>Ron Ullmann</li>
                <li>Miroslav Vaic</li>
                <li>Amanda van Adrichem</li>
                <li>Stephen Van Eck</li>
                <li>Marion and Lloyd Van Winkle</li>
                <li>Charlene Vanacker</li>
                <li>David L. Vernier</li>
                <li>George Wagner</li>
                <li>Adam R. Walker</li>
                <li>Judith Walker and George Hallenbeck</li>
                <li>Robert J. Walsh</li>
                <li>Wayd Weber</li>
                <li>Elizabeth Weinstock</li>
                <li>Stephen White</li>
                <li>Alan C. Whitmore</li>
                <li>Charles R. Wiggins</li>
                <li>Larry Wilson</li>
                <li>Larry Wilson</li>
                <li>Kent Winterholler</li>
                <li>Thomas Witek</li>
                <li>Tad Witkowicz</li>
                <li>Benjamin L. Wolozin and Danielle Murstein</li>
                <li>Jim A. Wooldridge</li>
                <li>Courtney Wright</li>
                <li>David Wright</li>
                <li>Edward Wysocki</li>
                <li>Mike Zulauf</li>
                <li>Bristol-Myers Squibb Foundation</li>
                <li>Rosenstein Family Donor Advised Fund</li>
                <li>Seligsohn Foundation</li>
                <li>Steve &amp; Gayle Brugler Fund</li>
                <li>United Way of Central Indiana</li>
                <li>Anonymous (7)</li>
              </ul>
            </div>
          </div>
        </div>
        {/* New Button */}
      </div>
    </div>

    <div className="stats-row">
      <div
        id="join-now-column"
        className="lite-grey center small-shadow corners col-12 col-xl-6"
      >
        <h4>Powered By Your Passion</h4>
        <p>
          There has never been a greater need for an organization like the
          Center for Inquiry, championing facts, reason, truth, science, and
          secularism, all at a time that they are all under threat by the
          changing tides of national and global events. Everyone who shares
          these core values will be needed. CFI needs you to be part of the
          solution, to join the good guys.
        </p>
        <a href="https://centerforinquiry.org/support-cfi/" target="_blank">
          <button className="btn orange-button-ghost">Join Now</button>
        </a>
      </div>
    </div>
  </div>
)

export default SectionDonors
