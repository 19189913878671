import * as React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const SaganPage = () => (
  <>
    <div class="sagan-container">
      <div class="flex-left">
        <p>
          The truth has had a difficult few years. Even before the COVID-19
          pandemic, when the media landscape became overwhelmed with
          misinformation, a new age had already dawned for pseudoscience,
          conspiracy theories, religious zealotry, medical quackery, and plain
          old lies.
        </p>
        <p>
          It was no accident. One of the chief architects of the misinformation
          age, Steve Bannon, confessed as much back in 2018. His true enemy, he
          said, was not political opposition, but the truth. His strategy? To
          “flood the zone” with a euphemism for excrement; to saturate all media
          with lies and disinformation; and to wallow in the resulting sludge.
          When no one can trust anything they hear, they will believe what they
          want, regardless of whether it’s true.
        </p>
        <p>We have been gearing up for this fight since the beginning.</p>
        <p>
          For more than four decades, the Center for Inquiry has stood as a
          beacon of truth in a storm of lies, advancing reason over fantasy and
          science over myth. In 2021, CFI—a transnational organization
          encompassing a dynamic array of programs to advance a secular society
          based on reason, science, freedom of inquiry, and humanist values—met
          this crucial moment in human history with a renewed emphasis on what
          it does best: producing good ideas.
        </p>
        <p>
          Over the past year, the Center for Inquiry has expanded and
          re-energized its content creation, building on its foundational
          publications such as <em>Skeptical Inquirer</em> magazine,{" "}
          <em>Free Inquiry</em> magazine, and the podcast{" "}
          <em>Point of Inquiry</em>, and branching out into new mediums and
          platforms, while bringing new voices to larger audiences than ever
          before.
        </p>
        <p>
          The masters of misinformation pose a serious threat to so much of what
          we hold dear. But they don’t scare us. Try as they might, we are
          gathering up the candles in the dark first sparked by heroes of reason
          such as Carl Sagan, and with the support of this exceptional
          community, we will flood the zone with light.
        </p>
      </div>
      <div class="flex-right">
        <StaticImage
          src="../images/Sagan.jpg"
          placeholder="tracedSVG"
          alt="Carl Sagan"
        />
        <div className="sagan-blockquote">
          <p>
            <em>
              One of the saddest lessons of history is this: If we’ve been
              bamboozled long enough, we tend to reject any evidence of the
              bamboozle. We’re no longer interested in finding out the truth.
              The bamboozle has captured us. It’s simply too painful to
              acknowledge, even to ourselves, that we’ve been taken. Once you
              give a charlatan power over you, you almost never get it back.
            </em>
          </p>
        </div>

        <div>
          <p>
            <strong>&ndash; Carl Sagan</strong>, one of the founders of what
            would become the Center for Inquiry, from
            <em> The Demon-Haunted World: Science as a Candle in the Dark </em>
            (1995)
          </p>
        </div>
      </div>
    </div>
  </>
)

export default SaganPage
