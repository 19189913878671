import * as React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import SaganPage from "../components/sagan.js"

const FAKE_MEDICINE_QUERY = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: frontmatter___order, order: ASC }
      filter: { frontmatter: { tag: { eq: "fake-medicine" } } }
    ) {
      nodes {
        html
        frontmatter {
          title
          tag
          format
          featuredImage {
            publicURL
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: WEBP)
            }
          }
        }
      }
    }
  }
`

const SectionFakeMedicine = props => {
  return (
    <>
      <SaganPage />
      <StaticQuery
        query={FAKE_MEDICINE_QUERY}
        render={({ allMarkdownRemark: { nodes: posts } }) => {
          return posts.map(post => (
            <>
              <div className="container-fluid module-container ">
                <div className="row mod-image">
                  {post.frontmatter.featuredImage?.childImageSharp
                    ?.gatsbyImageData && (
                    <GatsbyImage
                      image={
                        post.frontmatter.featuredImage.childImageSharp
                          .gatsbyImageData
                      }
                      alt={post.frontmatter.title}
                      className="col-12 col-md-6 mod-img"
                      objectFit="cover"
                      loading="lazy"
                    />
                  )}

                  <div className="col-12 col-md-6">
                    {post.frontmatter.format == "full" ? (
                      <>
                        <div className="arrow"></div>
                        <div className="mod-text">
                          <div>
                            {post.frontmatter.title && (
                              <h5>{parse(post.frontmatter.title)}</h5>
                            )}
                            {parse(post.html)}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="arrow dark"></div>
                        <div className="pull-quote solo">
                          <div>
                            {post.frontmatter.title && (
                              <h5>{parse(post.frontmatter.title)}</h5>
                            )}
                            {parse(post.html)}
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ))
        }}
      />
    </>
  )
}

export default SectionFakeMedicine
