import * as React from "react"

const StatsWeb = () => (
  <div className="row inline-stats">
    <div className="col-12 col-xl-8 body-stats corners">
      <h4 className="grey-text">WEB STATS</h4>
      <hr />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-03.svg"
          />
          <h1>7 Million</h1>
          <p>
            CFI Websites 2021 Total Pageviews
            <br />
            <strong>581,000</strong> Average Monthly Pageviews
          </p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-15.svg"
          />
          <h1>7.6 million</h1>
          <p>
            RDFRS Website 2021 Total Pageviews
            <br />
            <strong>660,000</strong> Average Monthly Pageviews
          </p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-10 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-18.svg"
          />
          <h1>
            <strong>Over 14.6 million</strong>
          </h1>
          <p>Total Organization Pageviews in 2021</p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-11.svg"
          />
          <h1>378</h1>
          <p>Online articles published</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-01.svg"
          />
          <h1>16</h1>
          <p>Web columns/columnists</p>
        </div>
      </div>
    </div>
  </div>
)

export default StatsWeb
