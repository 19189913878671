import * as React from "react"

const StatsSM = () => (
  <div className="row inline-stats">
    <div className="col-12 col-xl-8 body-stats corners">
      <h4 className="grey-text">SOCIAL MEDIA</h4>
      <hr />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-09.svg"
          />
          <h1>60,500</h1>
          <p>CFI YouTube Subscribers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-09.svg"
          />
          <h1>1.5 million</h1>
          <p>CFI YouTube channel views</p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-09.svg"
          />
          <h1>365,000</h1>
          <p>RDFRS YouTube Subscribers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-09.svg"
          />
          <h1>2.4 million</h1>
          <p>RDFRS YouTube channel views</p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/wp-content/themes/progress2019/img/icons/progress-report-17-graphics-01.svg"
          />
          <h1>345,000 views</h1>
          <p>
            Most-viewed RDFRS 2021 YouTube Video: <br />{" "}
            <strong>
              “An Evening with Richard Dawkins—Featuring Sam Harris”
            </strong>
          </p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/wp-content/themes/progress2019/img/icons/progress-report-17-graphics-01.svg"
          />
          <h1>60,100 views</h1>
          <p>
            Most-viewed CFI YouTube Video: <br />{" "}
            <strong>“Tim Minchin and Richard Dawkins in Conversation”</strong>
          </p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-08.svg"
          />
          <h1>64,000</h1>
          <p>CFI Twitter Followers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-05.svg"
          />
          <h1>105,200</h1>
          <p>CFI Facebook Followers</p>
        </div>
      </div>
      <br />

      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-3 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-08.svg"
          />
          <h1>105,200</h1>
          <p>RDFRS Twitter Followers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-4 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-05.svg"
          />
          <h1>1,400,000</h1>
          <p>RDFRS Facebook Followers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-3 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/instagram-icon.svg"
          />
          <h1>49,100</h1>
          <p>RDFRS Instagram Followers</p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-08.svg"
          />
          <h1>49,200</h1>
          <p>Skeptical Inquirer Twitter followers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-05.svg"
          />
          <h1>150,000</h1>
          <p>Skeptical Inquirer Facebook followers</p>
        </div>
      </div>
      <br />
    </div>
  </div>
)

export default StatsSM
