import * as React from "react"

const StatsSIP = () => (
  <div className="row inline-stats">
    <div className="col-12 col-xl-8 body-stats corners">
      <h4 className="grey-text">SKEPTICAL INQUIRER PRESENTS</h4>
      <hr />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-01.svg"
          />
          <h1>1422 </h1>
          <p>
            Skeptical Inquirer Presents most live event attendees
            <br />
            (Neil deGrasse Tyson)
          </p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-5 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-09.svg"
          />
          <h1>14,000</h1>
          <p>Skeptical Inquirer Presents live attendees</p>
        </div>
      </div>
      <br />
    </div>
  </div>
)

export default StatsSIP
