import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"

import RobynLetter from "../components/robyn-letter"
import SectionFakeMedicine from "../components/section-fake-medicine"
import SectionReawakeningSkepticism from "../components/section-reawakening-skepticism"
import SectionFortifyingSecularism from "../components/section-fortifying-secularism"
import SectionDefendingReality from "../components/section-defending-reality"
import SectionSharingEnlightenment from "../components/section-sharing-enlightenment"
import SectionDonors from "../components/section-donors"
import StatsSIP from "../components/stats-sip"
import StatsWeb from "../components/stats-web"
import StatsSM from "../components/stats-social-media"
import StatsSRTP from "../components/stats-sr-tp"
import StatsEmail from "../components/stats-email"
import splashlogo from "../images/cfi-light-letters.svg"

import Seo from "../components/seo"
import parse from "html-react-parser"

export default function Home() {
  return (
    <Layout>
      {/* Landing Area */}
      <div id="home-top">
        <div className="container-fluid" id="landing-outer">
          <div
            id="landing-inner"
            className="row align-items-center justify-content-center "
          >
            <div id="landing-splash">
              <StaticImage
                src="../images/cfi-light-letters.png"
                placeholder="none"
                loading="eager"
                alt="CFI Progress 2021"
                transformOptions={{ fit: "contain" }}
              />
            </div>
          </div>
          <div id="landing-text">
            <h2>
              FLOODING THE ZONE
              <br />
              WITH LIGHT
            </h2>
            <p>
              <em>
                We opened minds to the power of reason.
                <br />
                We led the fight against fake medicine.
                <br />
                We gave voice to lifesaving science.
              </em>
            </p>
          </div>
        </div>
      </div>

      <RobynLetter />
      <SectionFakeMedicine />
      <StatsSIP />
      <StatsWeb />
      <SectionReawakeningSkepticism />
      <SectionFortifyingSecularism />
      <SectionDefendingReality />
      <StatsSM />
      <SectionSharingEnlightenment />
      <StatsSRTP />
      <StatsEmail />
      <SectionDonors />
    </Layout>
  )
}
