import * as React from "react"

const StatsEmail = () => (
  <div className="row inline-stats">
    <div className="col-12 col-xl-8 body-stats corners">
      <h4 className="grey-text">STAYING CONNECTED</h4>
      <hr />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-10 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-02.svg"
          />
          <h1>19</h1>
          <p>CFI Branches in the US and International</p>
        </div>
      </div>
      <br />
      <div className="card-deck row">
        <div className="card mid-shadow corners white text-center col-md-3 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-04.svg"
          />
          <h1>87,000+</h1>
          <p>CFI email subscribers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-4 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-04.svg"
          />
          <h1>25,000+</h1>
          <p>RDFRS email subscribers</p>
        </div>
        <div className="card mid-shadow corners white text-center col-md-3 mx-md-3">
          <img
            className="glance-icon"
            src="https://centerforinquiry.org/progress/wp-content/themes/progress2020/img/icons/progress-report-17-graphics-04.svg"
          />
          <h1>3700</h1>
          <p>
            <em>The Morning Heresy</em> subscribers
            <br />
            Almost double from 2020
          </p>
        </div>
      </div>
      <br />
    </div>
  </div>
)

export default StatsEmail
